import { cva, type VariantProps } from "class-variance-authority";
import { type ComponentPropsWithoutRef, forwardRef, type ReactNode, useId } from "react";
import { RiArrowDownSLine } from "react-icons/ri";
import { useField } from "remix-validated-form";

import { FieldError, FieldInfo } from "~/components/ui/forms/field-messages";
import { Label } from "~/components/ui/forms/label";
import { cn } from "~/utils/classnames";

const selectStyles = cva(
  [
    "block w-full appearance-none rounded-full border border-grey-300 bg-white placeholder:text-grey-400",
    "aria-invalid:border-danger-500 aria-invalid:text-danger-700 aria-invalid:ring-danger-500 aria-invalid:placeholder:text-danger-300",
    "disabled:cursor-not-allowed disabled:opacity-60",
  ],
  {
    variants: {
      size: {
        xs: "h-6 px-3 text-xs",
        sm: "h-8 px-4 text-sm",
        default: "h-10 pl-4 pr-11 text-sm",
        lg: "h-12 px-5 text-base",
      },
    },
    defaultVariants: {
      size: "default",
    },
  }
);

const arrowStyles = cva(
  "pointer-events-none absolute right-2 top-1/2 size-5 -translate-y-1/2 text-grey-400",
  {
    variants: {
      size: {
        xs: "right-3",
        sm: "right-4",
        default: "right-4",
        lg: "right-5",
      },
    },
    defaultVariants: {
      size: "default",
    },
  }
);

export interface SelectProps
  extends VariantProps<typeof selectStyles>,
    Omit<ComponentPropsWithoutRef<"select">, "size"> {
  label?: ReactNode;
  options?: { label: string; value: string }[];
  infoMessage?: ReactNode;
  errorMessage?: ReactNode;
  placeholder?: string;
}

export const Select = forwardRef<HTMLSelectElement, SelectProps>(function Select(
  { id, label, options = [], infoMessage, errorMessage, placeholder, size, className, ...props },
  ref
) {
  const genId = useId();

  return (
    <div className={cn("group", className)} aria-disabled={props.disabled}>
      {label ? (
        <Label htmlFor={id || genId} required={props.required} inputSize={size}>
          {label}
        </Label>
      ) : null}
      <div className={cn("relative", label && "mt-1.5")}>
        <select
          id={id || genId}
          aria-invalid={!!errorMessage}
          aria-describedby={`${id || genId}-info ${id || genId}-error`}
          className={cn(selectStyles({ size }))}
          ref={ref}
          defaultValue={props.value ? undefined : props.defaultValue || ""}
          {...props}
        >
          {placeholder ? (
            <option value="" disabled>
              {placeholder}
            </option>
          ) : null}
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <RiArrowDownSLine className={cn(arrowStyles({ size }))} />
      </div>
      {infoMessage ? (
        <FieldInfo inputId={id || genId} inputSize={size}>
          {infoMessage}
        </FieldInfo>
      ) : null}
      {errorMessage ? (
        <FieldError inputId={id || genId} inputSize={size}>
          {errorMessage}
        </FieldError>
      ) : null}
    </div>
  );
});

export const ValidatedSelect = forwardRef<HTMLSelectElement, SelectProps>(function ValidatedSelect(
  props: SelectProps,
  ref
) {
  const { error, getInputProps } = useField(props.name ?? "");

  return <Select ref={ref} {...props} {...getInputProps({ errorMessage: error, ...props })} />;
});
